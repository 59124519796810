.image-holder {
	img {
		width: 100%;
	}
}

.grayscale {
	-webkit-filter: grayscale(100%);
	filter: grayscale(100%);
}

#map {
	height: 470px;
	width: 100%;
}

.light-gray {
	background-color: $very-light-gray;
}

.top-bar {
	@include breakpoint(medium down) {
		background-color: $light-gray;

		ul {
			background-color: $light-gray;
		}
	}
	@include breakpoint(large) {
		.top-bar-right {
			li {
				margin-left: 10px;
			}
		}
	}
}

.menu {
	li {
		&.menu-text {
			font-size: 200%;
		}
		@include breakpoint(medium down) {
			&.is-active {
				border-left: 2px solid $yellow;
			}
		}
		@include breakpoint(large) {
			&.is-active {
				border-top: 2px solid $yellow;
			}
		}
	}
}

footer {
	.facebook {
		-webkit-filter: grayscale(100%);
		filter: grayscale(100%);
		&:hover {
			-webkit-filter: none;
			filter: none;
		}
	}
}

p {
	a:not(.button) {
		//color: $alert-color;
		text-decoration: underline;
		font-weight: bold;
	}
}

.vertical-spacing {
	padding-top: 12vh;
	padding-bottom: 12vh;
}

.button {
	.yellow {
		letter-spacing: 1px;
	}
}

.banner {
	background-size: contain;
	background-position: center center;
	background-repeat: no-repeat;
	height: 100vh;
	&.home {
		background-image: url('/img/airsoft-sofia-field-cover.png');
	}
}

// PARALLAX
//.banner {
//	background-position: center center;
//	background-repeat: no-repeat;
//	@include breakpoint(large) {
//		background-attachment: fixed;
//	}
//
//	&.home {
//		height: 100vh;
//		background-image: url('/img/airsoft-sofia-field-cover.png');
//		background-size: contain;
//	}
//
//	@include breakpoint(large) {
//		&.field {
//			height: 650px;
//			background-image: url('/img/airsoft-sofia-field.jpg');
//			background-size: cover;
//		}
//
//		&.guns {
//			height: 750px;
//			background-image: url('/img/airsoft-sofia-field-guns.jpg');
//			background-size: cover;
//		}
//	}
//}
// END OF PARALLAX
